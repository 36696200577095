import { useState } from "react";
import { uploadImage } from "../../../services/PromotionServices";
import { postListBanner } from "../../../services/SlideAds";
import { toast } from "react-toastify";
import "./styles.css";

const ModalAddNew = (props) => {
  const { show, handleClose, handleUpdateTable } = props;
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [nameTitle, setNameTitle] = useState("");
  const [content, setContent] = useState("");

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };

  const setClose = () => {
    setPreviewImage(undefined);
    setNameTitle("");
    setContent("");
    handleClose();
  };

  const upload = () => {
    setProgress(0);

    uploadImage(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        console.log("Uploaded image link:", response.link);
        postInProductInApp(response.link);
      })
      .catch((err) => {
        setProgress(0);
        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the image!");
        }
        setCurrentFile(undefined);
      });
  };

  const postInProductInApp = async (imageLink) => {
    let responseData = await postListBanner(
      nameTitle,
      `https://api-backends.frys-promotion.com/api/images${imageLink}`,
      content
    );
    if (responseData) {
      handleClose();
      handleUpdateTable();
      toast.success("Banner added successfully");
    } else {
      toast.error("Failed to add banner");
    }
  };

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 ${
        show ? "" : "hidden"
      }`}
    >
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg mx-auto p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Add New Production</h3>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={setClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="space-y-4">
          <div className="flex items-center">
            <label className="block text-sm font-medium text-gray-700 mr-4">
              Chọn Ảnh
            </label>
            <input
              type="file"
              className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              onChange={selectFile}
            />
          </div>

          {currentFile && (
            <div className="relative w-full h-4 bg-gray-200 rounded">
              <div
                className="absolute top-0 h-full bg-blue-500 rounded"
                style={{ width: `${progress}%` }}
              >
                <span className="absolute right-2 text-white text-xs">
                  {progress}%
                </span>
              </div>
            </div>
          )}

          {previewImage && (
            <div>
              <img
                src={previewImage}
                alt="Preview"
                className="w-full h-auto rounded"
              />
            </div>
          )}

          {message && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded relative">
              {message}
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Tên Tiêu Đề
            </label>
            <input
              type="text"
              className="block w-full mt-1 p-2 border rounded"
              value={nameTitle}
              onChange={(event) => setNameTitle(event.target.value)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Content
            </label>
            <input
              type="text"
              className="block w-full mt-1 p-2 border rounded"
              value={content}
              onChange={(event) => setContent(event.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end mt-6 space-x-3">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            onClick={setClose}
          >
            Đóng
          </button>
          <button
            className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${
              !previewImage || !nameTitle || !content
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={!previewImage || !nameTitle || !content}
            onClick={upload}
          >
            Thêm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAddNew;
