/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  uploadImage,
  putInProduction,
  deleteFile,
} from "../../../services/PromotionServiceHomePage";
import "./styles.css";

const ModalEditUser = (props) => {
  const { show, handleClose, dataProductEdit, handleUpdateTable } = props;
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [previewImageDidProduct, setPreviewImageDidProducte] =
    useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [nameProducts, setNameProducts] = useState("");
  const [price, setPrice] = useState("");
  const [commissionDiscount, setCommissionDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [active, setActive] = useState(false);

  const selectFile = (event) => {
    setActive(true);
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };

  const setClose = () => {
    setActive(false);
    setPreviewImage(undefined);
    setPreviewImageDidProducte(undefined);
    setPrice(undefined);
    setCommissionDiscount(undefined);
    setNameProducts(undefined);
    setDescription(undefined);
    setCategory(undefined);
    handleClose();
  };

  const getProductionInApp = () => {
    setPreviewImageDidProducte(dataProductEdit.image);
    setPrice(dataProductEdit.price);
    setCommissionDiscount(dataProductEdit.commission_discount);
    setNameProducts(dataProductEdit.name_product);
    setDescription(dataProductEdit.description);
    setCategory(dataProductEdit.category);
  };

  useEffect(() => {
    if (show) {
      getProductionInApp();
    }
  }, [dataProductEdit, show]);

  const upload = async () => {
    const result = String(dataProductEdit.image).substring(80, 52);
    let dataResult = await deleteFile(result);

    setProgress(0);

    uploadImage(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        putInProductInApp(
          `https://api-backends.frys-promotion.com/api/images${response.link}`
        );
      })
      .catch((err) => {
        setProgress(0);
        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the Image!");
        }
        setCurrentFile(undefined);
      });
  };

  const putInProductInApp = async (imageLink) => {
    const ratting = { rate: 0, count: 0 };
    let responseData = await putInProduction(
      dataProductEdit.id,
      nameProducts,
      price,
      commissionDiscount,
      description,
      category,
      imageLink,
      ratting
    );
    if (responseData && responseData.status) {
      handleClose();
      handleUpdateTable();
      toast.success("A product was successfully updated");
    } else {
      toast.error("Product update failed");
    }
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <>
      {show && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg w-full max-w-lg mx-4 shadow-lg">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-semibold">Edit Product</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={setClose}
              >
                ✕
              </button>
            </div>

            <div className="p-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Select Image
                </label>
                <input
                  type="file"
                  name="photo"
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={selectFile}
                />
              </div>

              {currentFile && (
                <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              )}

              {active === false ? (
                <img
                  className="w-full h-auto mb-4"
                  src={previewImageDidProduct}
                  alt="Preview"
                />
              ) : (
                <img
                  className="w-full h-auto mb-4"
                  src={previewImage}
                  alt="Preview"
                />
              )}

              {message && (
                <div className="bg-yellow-100 text-yellow-800 p-2 rounded mb-4">
                  {message}
                </div>
              )}

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Product Name
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
                  value={nameProducts}
                  onChange={(event) => setNameProducts(event.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Price (VND)
                </label>
                <input
                  type="number"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
                  value={price}
                  onChange={(event) => setPrice(event.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Commission (VND)
                </label>
                <input
                  type="number"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
                  value={commissionDiscount}
                  onChange={(event) =>
                    setCommissionDiscount(event.target.value)
                  }
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <select
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500"
                  value={category}
                  onChange={handleChange}
                >
                  <option value={"Phổ thông"}>Phổ thông</option>
                  <option value={"Tiểu thương"}>Tiểu thương</option>
                  <option value={"Thương Gia"}>Thương Gia</option>
                  <option value={"Đại lý tiktok"}>Đại lý tiktok</option>
                  <option value={"Doanh nghiệp"}>Doanh nghiệp</option>
                  <option value={"VIP"}>VIP</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end p-4 border-t">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600"
                onClick={setClose}
              >
                Close
              </button>
              <button
                className={`bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 ${
                  !nameProducts ||
                  !price ||
                  !commissionDiscount ||
                  !description ||
                  !category
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                onClick={
                  active
                    ? upload
                    : () => putInProductInApp(dataProductEdit.image)
                }
                disabled={
                  !nameProducts ||
                  !price ||
                  !commissionDiscount ||
                  !description ||
                  !category
                }
              >
                Update Product
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalEditUser;
