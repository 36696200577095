import { useState } from "react";
import { uploadImage } from "../../../services/PromotionServices";
import { postListBanner } from "../../../services/SlideAdsEndNow";
import { toast } from "react-toastify";
import "./styles.css";

const ModalAddNew = (props) => {
  const { show, handleClose, handleUpdateTable } = props;
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [nameTitle, setNameTitle] = useState("");
  const [content, setContent] = useState("");

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };

  const setClose = () => {
    setPreviewImage(undefined);
    setNameTitle("");
    setContent("");
    handleClose();
  };

  const upload = () => {
    setProgress(0);

    uploadImage(currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        postInProductInApp(response.link);
      })
      .catch((err) => {
        setProgress(0);

        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the Image!");
        }

        setCurrentFile(undefined);
      });
  };

  const postInProductInApp = async (imageLink) => {
    let responseData = await postListBanner(
      nameTitle,
      `https://api-backends.frys-promotion.com/api/images${imageLink}`,
      content
    );
    if (responseData) {
      handleClose();
      handleUpdateTable();
      toast.success("A user success");
    } else {
      toast.error("A user failed");
    }
  };

  return (
    <div
      className={`fixed inset-0 z-50 ${
        show ? "flex" : "hidden"
      } items-center justify-center bg-black bg-opacity-50`}
    >
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-semibold">Add New Production</h3>
          <button
            onClick={() => setClose()}
            className="text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
        <div className="p-4">
          <div className="mb-4">
            <label className="block mb-2">Chọn Ảnh</label>
            <input
              type="file"
              name="photo"
              className="block w-full border rounded-md p-2"
              onChange={selectFile}
            />
          </div>
          {currentFile && (
            <div className="my-3">
              <div
                className="bg-blue-200 h-2 rounded"
                style={{ width: `${progress}%` }}
              />
              <span>{progress}%</span>
            </div>
          )}
          {previewImage && (
            <div className="mb-4">
              <img
                className="w-full h-auto rounded"
                src={previewImage}
                alt="Preview"
              />
            </div>
          )}
          {message && (
            <div className="bg-gray-100 text-gray-700 p-2 rounded mb-3">
              {message}
            </div>
          )}
          <div className="mb-3">
            <label className="block mb-2">Tên Tiêu Đề</label>
            <input
              type="text"
              className="block w-full border rounded-md p-2"
              value={nameTitle}
              onChange={(event) => setNameTitle(event.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="block mb-2">Content</label>
            <input
              type="text"
              className="block w-full border rounded-md p-2"
              value={content}
              onChange={(event) => setContent(event.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-end p-4 border-t">
          <button
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md mr-2 hover:bg-gray-400"
            onClick={() => setClose()}
          >
            Đóng
          </button>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            disabled={
              previewImage === undefined || nameTitle === "" || content === ""
            }
            onClick={() => upload()}
          >
            Thêm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalAddNew;
