/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { FaBars, FaTimes, FaSearch, FaUser } from "react-icons/fa";
import { UserContext } from "../context/useContext";
import { BsChevronDown } from "react-icons/bs";

const Navbars = ({
  toggleSidebar,
  isSidebarOpen,
  isMobile,
  activePage,
  setActivePage,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const { logout, user } = useContext(UserContext);
  const location = useLocation();
  // const [hideHeader, setHideHeader] = useState(false);

  // useEffect(() => {
  //   if (window.location.pathname === "/login") {
  //     setHideHeader(true);
  //   }
  // }, []);

  const navigate = useNavigate();
  const handleClickSignOut = () => {
    logout();
    navigate("/");
  };

  return (
    <header className="bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-md">
      <div className="container mx-auto px-4 py-3 flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center">
          <img
            src="https://images.unsplash.com/photo-1599305445671-ac291c95aaa9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8bG9nb3x8fHx8fDE2ODQ0MDcyMDM&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080"
            alt="Logo"
            className="h-8 w-auto mr-2"
          />
          <span className="text-xl font-bold">Fry's Manager</span>
        </div>

        {/* Mobile menu button */}
        {isMobile && (
          <button
            onClick={toggleSidebar}
            className="text-white focus:outline-none focus:text-gray-300"
            aria-label="Toggle sidebar"
          >
            {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        )}

        {/* Desktop navigation */}
        {!isMobile && (
          <nav className="hidden md:flex space-x-4">
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="flex items-center text-white hover:text-gray-300 px-3 py-2 rounded-md text-sm font-medium focus:outline-none"
                aria-expanded={isDropdownOpen}
                aria-haspopup="true"
              >
                <FaUser size={20} /> <BsChevronDown className="ml-1" />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <a
                    href="/change_password_admin"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Đổi mật khẩu
                  </a>
                  <a
                    onClick={() => handleClickSignOut()}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Đăng Xuất
                  </a>
                </div>
              )}
            </div>
          </nav>
        )}

        {/* Search and user profile */}
        {/* <div className="hidden md:flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search"
              className="bg-blue-600 text-white placeholder-blue-200 rounded-full py-1 px-3 pr-8 focus:outline-none focus:ring-2 focus:ring-white"
            />
            <FaSearch className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-200" />
          </div>
          <button
            className="text-white hover:text-gray-300 focus:outline-none"
            aria-label="User profile"
          >
            <FaUser size={20} />
          </button>
        </div> */}
      </div>
    </header>
  );
};

export default Navbars;
