import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getUserGuestId,
  postUserAPI,
  updatePasswordPin,
  updatePasswordkey,
  updateResetBank,
  setCoinToFreezeBank,
  setFreezeBankToCoin,
} from "../../services/ProfileServices";
import {
  getPageTransactionId,
  deleteRandomOrder,
} from "../../services/TransactionService";
import { getProductionInCode } from "../../services/PromotionServices";
import {
  getListVipGet,
  postUserViper,
  deleteAPiViper,
} from "../../services/ServiceVipTiktok";
///
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import NotFound from "../NotFound/NotFound";
import ModalAddMoney from "./components/modalAddMoney";
import ModalPasswordNew from "./components/modalPasswordNew";
import ModalUpdateMoney from "./components/modalEditMoney";
import ModalProductionCheck from "./components/modalProductIdCheck";
// import {
//   FaUser,
//   FaEnvelope,
//   FaPencilAlt,
//   FaCamera,
//   FaFacebook,
//   FaTwitter,
//   FaLinkedin,
// } from "react-icons/fa";
// import { ClipLoader } from "react-spinners";

const PageInfoProfile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activePage, setActivePage] = useState("Home");
  const [isMobile, setIsMobile] = useState(false);

  //   useEffect(() => {

  //   }, []);

  let { id } = useParams();
  const [statusResults, setStatusResults] = useState("");

  const [isShowModalAddPrice, setIsShowModalAddPrice] = useState(false);
  const [isShowModalUpdatePrice, setIsShowModalUpdatePrice] = useState(false);
  const [isShowModalPassword, setIsShowModalPassword] = useState(false);
  /////
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordNew, setPasswordNew] = useState("");

  // const [setVip, setSetVip] = useState("");
  const [typeSet, setTypeSet] = useState("");
  const [price, setPrice] = useState("");
  const [lenghtMatch, setLenghtMatch] = useState("");
  const [edtFreezeCoin, setEdtFreezeCoin] = useState(0);
  const [edtFreezeCoinV2, setEdtFreezeCoinV2] = useState(0);
  const [paymentBank, setPaymentBank] = useState({});

  /////
  const [listProductVip, setListProductVip] = useState([]);
  const [lstVipAdd, setLstVipAdd] = useState([]);
  const [typeVip, setTypeVip] = useState("");
  const [levelVip, setLevelVip] = useState(0);
  const [maxiumCode, setMaxiumCode] = useState(0);

  /////
  const [pages, setPages] = useState(0);
  const [totalsPages, setTotalsPages] = useState(0);
  const [listPages, setListPages] = useState([]);
  const [modalCheckProduction, setModalCheckProduction] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [myProduction, setMyProduction] = useState({});

  const navigate = useNavigate();

  /// form

  const handleBack = () => {
    navigate("/manager_profile");
  };

  const handleCloseShow = () => {
    setIsShowModalAddPrice(false);
    setIsShowModalPassword(false);
    setIsShowModalUpdatePrice(false);
    setModalCheckProduction(false);
  };

  useEffect(() => {
    getAPIGuest();
    getAPIProductVIP();
    getVipAdd();
    getAPITransactionShow(1);
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getAPITransactionShow = async (page) => {
    const restAPI = await getPageTransactionId(id, page);
    if (restAPI) {
      setPages(restAPI.page);
      setTotalsPages(restAPI.totalPages);
      setListPages(restAPI.listPage);
    }
  };

  const getAPIGuest = async () => {
    const resAPI = await getUserGuestId(id);
    // console.log("hhhhhhh", resAPI.results.user_name);
    setUserName(resAPI.results.user_name);
    setPhoneNumber(resAPI.results.phone_number);
    setTypeSet(resAPI.results.vip_change);
    setPrice(resAPI.results.coin_user);
    setStatusResults(resAPI.status);
    setPaymentBank(resAPI.results.payment_bank);
    setLenghtMatch(resAPI.results.dataVipChange.lenghtMatch);
    setMaxiumCode(resAPI.results.dataVipChange.maxiumCode);
    setEdtFreezeCoin(resAPI.results.freese_coin);
    setEdtFreezeCoinV2(resAPI.results.freese_coin);
  };

  const handleSumbit = async () => {
    let resAPI = await postUserAPI(id, userName, phoneNumber, typeSet);
    console.log("res", resAPI);
    toast.success("Bạn đã cập nhật thành công");
  };

  const handleSumbitVip = async () => {
    let resAPI = await postUserViper(typeVip, id, levelVip);
    console.log("res", resAPI);
    toast.success("Bạn đã cập nhật thành công");

    getVipAdd();
  };

  const getAPIProductVIP = async () => {
    let resAPI = await getProductionInCode("VIP");
    setListProductVip(resAPI.results);
  };

  const getVipAdd = async () => {
    let resAPI = await getListVipGet(id);
    setLstVipAdd(resAPI.results);
  };

  const handleClickDelete = async (id) => {
    await deleteAPiViper(id);
    getVipAdd();
  };

  const newPasswordReset = async (id) => {
    const PassNew = makeid(8);
    setPasswordNew(`${PassNew}`);
    let resAPI = await updatePasswordkey(id, PassNew);
    if (resAPI) {
      setIsShowModalPassword(true);
    }
  };

  const newPassPin = async (id) => {
    let resAPI = await updatePasswordPin(id);
    if (resAPI) {
      toast.success("Bạn đã Reset Mã Pin");
    }
  };

  const resetBank = async (id) => {
    let resAPI = await updateResetBank(id);
    if (resAPI) {
      getAPIGuest();
      toast.success("Bạn đã Reset Ngân Hàng");
    }
  };

  const freezeCoinSet = async (id, freezeCoin) => {
    let resAPI = await setCoinToFreezeBank(id, freezeCoin);
    if (resAPI) {
      getAPIGuest();
      toast.success("Bạn đã set lệnh số tiền xử lý");
    }
  };
  const freezeCoinToRealSet = async (id) => {
    let resAPI = await setFreezeBankToCoin(id);
    if (resAPI) {
      getAPIGuest();
      toast.success("Bạn đã set lệnh số tiền xử lý");
    }
  };

  const handleUpdateBank = async (row) => {
    window.location.href = `/page_update_bank/${row}`;
  };

  const handleResetRandomButton = async () => {
    let resAPI = await deleteRandomOrder(id);
    if (resAPI) {
      toast.success("Bạn đã Reset Đơn Hàng Hôm Nay");
    }
  };

  function makeid(length) {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const handleClickShowProduction = (myJSON) => {
    setMyProduction(myJSON);
    setModalCheckProduction(true);
  };

  const handlePageClick = (event) => {
    getAPITransactionShow(event.selected + 1);
  };

  // const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  //add modal
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   bio: "",
  //   profilePicture: null,
  //   facebook: "",
  //   twitter: "",
  //   linkedin: "",
  // });
  // const [errors, setErrors] = useState({});
  // const [isLoading, setIsLoading] = useState(false);
  // const [previewImage, setPreviewImage] = useState(null);

  // const commonEmailDomains = [
  //   "gmail.com",
  //   "yahoo.com",
  //   "hotmail.com",
  //   "outlook.com",
  // ];

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  //   validateField(name, value);
  // };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFormData({ ...formData, profilePicture: file });
  //     setPreviewImage(URL.createObjectURL(file));
  //   }
  // };

  // const validateField = (name, value) => {
  //   let newErrors = { ...errors };
  //   switch (name) {
  //     case "name":
  //       if (!value.trim()) {
  //         newErrors.name = "Name is required";
  //       } else {
  //         delete newErrors.name;
  //       }
  //       break;
  //     case "email":
  //       if (!value) {
  //         newErrors.email = "Email is required";
  //       } else if (!/\S+@\S+\.\S+/.test(value)) {
  //         newErrors.email = "Email is invalid";
  //       } else {
  //         delete newErrors.email;
  //       }
  //       break;
  //     case "bio":
  //       if (value.length > 500) {
  //         newErrors.bio = "Bio should not exceed 500 characters";
  //       } else {
  //         delete newErrors.bio;
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  //   setErrors(newErrors);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (Object.keys(errors).length === 0) {
  //     setIsLoading(true);
  //     // Simulating API call
  //     await new Promise((resolve) => setTimeout(resolve, 2000));
  //     setIsLoading(false);
  //     alert("Profile updated successfully!");
  //   }
  // };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <main className="flex-1 p-4 overflow-auto">
        <div className="px-5  md:px-60">
          {" "}
          <button
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
            onClick={() => handleBack()}
          >
            Quay lại
          </button>
          <h2 className="text-lg font-semibold">Thông tin: {id}</h2>
        </div>
        {statusResults === "not-found-account" ? (
          <NotFound />
        ) : (
          <div className=" px-5  md:px-60">
            <h2 className="text-xl font-semibold">
              Coin: {Number(price).toLocaleString("en-US")} VNĐ
            </h2>
            <h2 className="text-xl font-semibold">
              Xếp hạng quay đơn: {lenghtMatch}
            </h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Họ và tên:</label>
                <input
                  type="text"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Nhập tên khách"
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Số điện thoại:</label>
                <input
                  type="number"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Nhập số điện thoại"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Sét hạng:</label>
                <select
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={typeSet}
                  onChange={(e) => setTypeSet(e.target.value)}
                >
                  <option value="Thành viên mới">Thành viên mới</option>
                  <option value={"Bạc"}>Bạc</option>
                  <option value={"Vàng"}>Vàng</option>
                  <option value={"Bạch Kim"}>Bạch Kim</option>
                  <option value={"Kim Cương"}>Kim Cương</option>
                  <option value={"ACE"}>ACE</option>
                  <option value={"VIP"}>VIP</option>
                </select>
              </div>

              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded w-full"
                onClick={() => handleSumbit()}
                type="button"
              >
                Cập nhật
              </button>
            </form>
            <hr />
            <div className="flex pt-5 pb-5">
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsShowModalAddPrice(true)}
                type="submit"
              >
                Nạp tiền
              </button>
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2"
                type="submit"
                onClick={() => setIsShowModalUpdatePrice(true)}
              >
                Chỉnh tiền cho khách
              </button>
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2"
                type="submit"
                onClick={() => newPassPin(id)}
              >
                Reset Mã PIN
              </button>
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2"
                type="submit"
                onClick={() => newPasswordReset(id)}
              >
                Reset Mật Khẩu
              </button>
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2"
                type="submit"
                onClick={() => resetBank(id)}
              >
                Reset Ngân Hàng
              </button>
              <button
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2"
                type="submit"
                onClick={() => handleResetRandomButton(id)}
              >
                Reset Đơn Hàng Hôm Nay
              </button>
              {paymentBank.account_name === undefined ? null : (
                <button
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2"
                  type="submit"
                  onClick={() => handleUpdateBank(id)}
                >
                  Sửa tài khoản ngân hàng
                </button>
              )}
            </div>
            <hr />
            {paymentBank.account_name === undefined ? (
              <p>Chưa có tài khoản</p>
            ) : (
              <div className="pt-5 pb-5">
                Tên tài khoản: {paymentBank.account_name}
                <br />
                Số tài khoản: {paymentBank.id_bank}
                <br />
                Ngân hàng: {paymentBank.more_back.name} -{" "}
                {paymentBank.more_back.shortName}
              </div>
            )}
            <hr />
            <div className="flex items-center">
              <div className="flex-grow">
                <label className="block text-gray-700">Số tiền xử lý:</label>
                <input
                  type="number"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  disabled={edtFreezeCoinV2 === 0 ? false : true}
                  placeholder="Nhập số tiền xử lý"
                  value={edtFreezeCoin}
                  onChange={(event) => setEdtFreezeCoin(event.target.value)}
                />
              </div>
              {edtFreezeCoinV2 === 0 ? (
                <button
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2 mt-4"
                  type="submit"
                  disabled={edtFreezeCoin >= price ? true : false}
                  onClick={() => freezeCoinSet(id, edtFreezeCoin)}
                >
                  Sét lệnh số tiền xử lý
                </button>
              ) : (
                <button
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded mx-2 mt-4"
                  type="submit"
                  onClick={() => freezeCoinToRealSet(id)}
                >
                  Về lệnh số 0 tài khoản chính
                </button>
              )}
            </div>
            <hr />
            <div className="mb-4">
              <label className="block text-gray-700">Gài đơn VIP</label>
              <select
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={typeVip}
                onChange={(e) => setTypeVip(e.target.value)}
              >
                {listProductVip.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name_product} - {e.price}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Cài thứ:</label>
              <input
                type="number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder={`Cài Số Vip không quá: ${maxiumCode}`}
                value={levelVip}
                onChange={(event) => setLevelVip(event.target.value)}
              />
            </div>
            <button
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded w-full"
              onClick={() => handleSumbitVip()}
              type="submit"
              disabled={
                levelVip === 0 ||
                levelVip < 1 ||
                levelVip > maxiumCode ||
                levelVip === ""
              }
            >
              Xác nhận gài Vip
            </button>
            <div className="border-b border-gray-200">
              <nav className="flex space-x-4" aria-label="Tabs">
                <button
                  className={`px-3 py-2 font-medium text-sm ${
                    activeTab === "tab1"
                      ? "text-blue-600 border-b-2 border-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => setActiveTab("tab1")}
                >
                  Quản Lý Vip
                </button>
                <button
                  className={`px-3 py-2 font-medium text-sm ${
                    activeTab === "tab2"
                      ? "text-blue-600 border-b-2 border-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => setActiveTab("tab2")}
                >
                  Quản lý đơn quay
                </button>
              </nav>
            </div>
            {activeTab === "tab1" ? (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sắp Sếp
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tên Sản Phẩm
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Giá sản phẩm
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {lstVipAdd &&
                      lstVipAdd.length > 0 &&
                      lstVipAdd.map((item, index) => (
                        <tr key={`user-${index}`}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item.sort}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item.name_product}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {Number(item.price).toLocaleString("en-US")} VND
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {Number(item.commission_discount).toLocaleString(
                              "en-US"
                            )}{" "}
                            VNĐ
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <button
                              className="bg-red-500 text-white font-bold py-2 px-4 rounded"
                              onClick={() => handleClickDelete(item.id)}
                            >
                              Xoá đơn vip
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Id
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Trạng thái
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Số tiền
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Hoa Hồng
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tổng tiền
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Id Sản Phẩm
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ngày giao dịch
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Xem
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {listPages &&
                      listPages.length > 0 &&
                      listPages.map((item, index) => (
                        <tr key={`user-${index}`}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item.id_generation}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item.status}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {Number(item.price_transaction).toLocaleString(
                              "en-US"
                            )}{" "}
                            VNĐ
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {Number(item.discount).toLocaleString("en-US")} VNĐ
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {Number(
                              item.price_transaction + item.discount
                            ).toLocaleString("en-US")}{" "}
                            VNĐ
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item.id_production}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item.date_create_at}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <button
                              className={`bg-blue-500 text-white font-bold py-2 px-4 rounded w-full ${
                                item.getProduction.status === "not-found"
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }`}
                              disabled={
                                item.getProduction.status === "not-found"
                              }
                              onClick={() =>
                                handleClickShowProduction(
                                  item.getProduction.results
                                )
                              }
                            >
                              Xem Sản Phẩm
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <ReactPaginate
                  nextLabel="Sau"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={totalsPages}
                  previousLabel="Trước"
                  pageClassName="inline-block mx-1"
                  pageLinkClassName="px-3 py-2 border border-gray-300 rounded-md"
                  previousClassName="inline-block mx-1"
                  previousLinkClassName="px-3 py-2 border border-gray-300 rounded-md"
                  nextClassName="inline-block mx-1"
                  nextLinkClassName="px-3 py-2 border border-gray-300 rounded-md"
                  breakLabel="..."
                  breakClassName="inline-block mx-1"
                  breakLinkClassName="px-3 py-2 border border-gray-300 rounded-md"
                  containerClassName="flex justify-center my-4"
                  activeClassName="bg-blue-500 text-white"
                />
              </div>
            )}
          </div>
        )}
      </main>
      <ModalAddMoney
        idGet={id}
        show={isShowModalAddPrice}
        handleClose={handleCloseShow}
        handleUpdateTable={getAPIGuest}
      />
      <ModalPasswordNew
        idGet={id}
        show={isShowModalPassword}
        handleClose={handleCloseShow}
        phoneNumber={phoneNumber}
        passwordReset={passwordNew}
      />
      <ModalUpdateMoney
        idGet={id}
        show={isShowModalUpdatePrice}
        priceChange={price}
        handleClose={handleCloseShow}
        handleUpdateTable={getAPIGuest}
      />
      <ModalProductionCheck
        show={modalCheckProduction}
        handleClose={handleCloseShow}
        idProduction={myProduction}
      />
    </div>
  );
};

export default PageInfoProfile;
